import React from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faInstagram, faFacebook, faYoutube} from "@fortawesome/free-brands-svg-icons"

const Socialbar = () => {
    return (
        <SocialbarWrapper>
            <div className="flex-container">
                <ul className="links">
                    <li className="links_item">
                        <a target="_blank" rel="noreferrer" aria-label="Instagram Link" href="http://www.instagram.com/amplifiedyyc"><FontAwesomeIcon icon={faInstagram}/></a>
                    </li>
                    <li className="links_item">
                        <a target="_blank" rel="noreferrer" aria-label="Youtube Link"href="http://www.youtube.com/channel/UCwyUjrDZkKoVhWzScHMrVgw"><FontAwesomeIcon icon={faYoutube}/></a>
                    </li>
                    <li className="links_item">
                        <a target="_blank" rel="noreferrer" aria-label="Facebook Link" href="http://www.facebook.com/AmplifiedYYC"><FontAwesomeIcon icon={faFacebook}/></a>
                    </li>
                </ul>
            </div>
        </SocialbarWrapper>
    )}
    
    const SocialbarWrapper = styled.div`
    position: fixed;
    z-index: 9;
    width: 5rem;
    height: 100vh;
    right: 0;
    padding: 0;
    margin: 0;
    .links{
        display: block;
        color: black;
        text-decoration: none;
        position: absolute;
        list-style: none;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        @media only screen and (max-width: 767px){
            display: none;
        }
    }
    .links_item{
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
    `
    export default Socialbar