import React from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faInstagram, faFacebook, faYoutube} from "@fortawesome/free-brands-svg-icons"

const Footer = () => {
    return (
        <FooterWrapper>
            <footer className="fixed-footer bg-img-cover js-fixed-footer" style={{backgroundColor:`#000`}}>
                <div className="bg-overlay-blue"></div>
                <div className="pos-rel text-color-white">
                    <div className="container padding-top-bottom-120">
						{/* <div class="footer-logo footer-logo-desktop-hidden padding-bottom-90">
							<h1>AMPLIFIED YYC</h1>
						</div> */}

                    	<div className="flex-container flex-align-center">
                        	<div className="eight-columns">
								<h4 className="column-l-r-margin-10 headline-l footer-title">
									We <span className="text-color-red">Want<br/>You</span> to<br/> Get Connected!
								</h4>
                        	</div>

                        	<div className="four-columns footer-nav-mobile-padding">
								<ul className="column-l-r-margin-10 footer-nav-list js-footer-hover-box">
									<li>
										<a href="/" className="footer-nav__btn js-pointer-small js-footer-hover-link">Home</a>
									</li>
									<li>
										<a href="/churchlaunch" className="footer-nav__btn js-pointer-small js-footer-hover-link">Church Launch</a>
									</li>
									<li>
										<a href="/smallgroups" className="footer-nav__btn js-pointer-small js-footer-hover-link">Small Groups</a>
									</li>
									<li>
										<a href="/teams" className="footer-nav__btn js-pointer-small js-footer-hover-link">Join a Team</a>
									</li>
									<li>
										<a href="/donate" className="footer-nav__btn js-pointer-small js-footer-hover-link">Give</a>
									</li>
								</ul>
                        	</div>
                    	</div>   

                		<div className="flex-container flex-justify-center padding-top-30">
							<div className="four-columns column-50-100 padding-top-60 footer-email">
								<div className="column-l-r-margin-10">
									<div className="headline-xxxxs">Email</div>
									<div className="margin-top-10">
										<a href="mailto:contact@amplifiedchurch.ca" className="subhead-xxs text-color-b0b0b0 text-hover-to-white js-pointer-small">contact@amplifiedchurch.ca</a><br/>
									</div>
								</div>
							</div>
                

							<div className="four-columns column-50-100 padding-top-60 footer-address">
								<div className="column-l-r-margin-10">
									{/* <div className="headline-xxxxs">Address</div> */}
									<div className="margin-top-10">
										<div href="/" className="subhead-xxs text-color-b0b0b0 text-hover-to-white js-pointer-small">
											{/* Calgary AB, CA */}
										</div>
									</div>
								</div>
							</div>

							<div className="four-columns column-50-100 padding-top-60 footer-phone">
								<div className="column-l-r-margin-10">
									<div className="headline-xxxxs">Address</div>
									<div className="margin-top-10">
										<a target="_blank" rel="noreferrer" aria-label="Google Maps Link" href="https://goo.gl/maps/kVZ1cG8MVBf9fAjT8" className="subhead-xxs text-color-b0b0b0 text-hover-to-white js-pointer-small">1801 10 Ave SW, Calgary, AB T3C 0K2</a><br />
									</div>
								</div>
							</div>
						</div>
				
						<ul className="footer-social-desktop-hidden list list_center padding-top-90">
							<li className="list__item padding-bottom-30">
							<a target="_blank" rel="noreferrer" aria-label="Instagram Link" href="http://www.instagram.com/amplifiedyyc" data-text="Instagram"><FontAwesomeIcon icon={faInstagram}/></a>
							</li>
							<li className="list__item padding-bottom-30">
							<a target="_blank" rel="noreferrer" aria-label="Youtube Link" href="http://www.youtube.com/channel/UCwyUjrDZkKoVhWzScHMrVgw" data-text="Youtube"><FontAwesomeIcon icon={faYoutube}/></a>
							</li>
							<li className="list__item padding-bottom-30">
							<a target="_blank" rel="noreferrer" aria-label="Facebook Link" href="http://www.facebook.com/AmplifiedYYC"><FontAwesomeIcon icon={faFacebook}/></a>
							</li>
						</ul>
						
						<div className="footer-copyright text-center pos-abs pos-bottom-center">
							<p className="copyright-style">
								&copy; Copyright 2021 Amplified YYC. Developed by <a href="http://www.jonathangirma.com" className="text-weight-700 js-pointer-small">Jonathan Girma</a>
							</p>
						</div>
					</div>
            	</div>
			</footer>
        </FooterWrapper>
    )
}

const FooterWrapper = styled.div`
/* ==========================================================================
   11. Footer
   ========================================================================== */

.fixed-footer {
	width: 100%;
	position: fixed;
	overflow: hidden;
	bottom: 0;
	z-index: 0;
	padding-top: 50rem;
	@media only screen and (max-width: 767px){
		position: relative;
		padding-top: 0;
	}
}


/* 11.1. Footer logo
   ========================================================================== */

.footer-logo {
	text-align: center;
}

.footer-logo__img {
	width: auto;
	height: 30px;
}

.footer-logo-desktop-hidden {
	display: none;
}

@media only screen and (max-width: 549px) {

	.footer-logo-desktop-hidden {
		display: block;
	}

}

/* Horizontal header */
.vertical .footer-logo__img {
	width: 30px;
	height: auto;
}


/* 11.2. Footer title & navigation
   ========================================================================== */

.footer-nav-list {
	text-align: right;
	list-style: none;
}

.footer-nav__btn {
	font-family: 'Montserrat', sans-serif;
	font-size: 14px;
	font-weight: 500;
	letter-spacing: 3px;
	line-height: 1.9;
	text-transform: uppercase;
}

.js-footer-hover-link {
	opacity: 1;
	-webkit-transition: 1s cubic-bezier(.17, .85, .438, .99);
	     -o-transition: 1s cubic-bezier(.17, .85, .438, .99);
	        transition: 1s cubic-bezier(.17, .85, .438, .99);
}

.js-footer-hover-box.js-footer-hover-active .js-footer-hover-link {
	opacity: .2;
}

.js-footer-hover-box.js-footer-hover-active .js-footer-hover-link:hover {
	opacity: 1;
}

@media only screen and (max-width: 767px) {

	.footer-title,
	.footer-nav-list {
		text-align: center;
	}

	.footer-nav-mobile-padding {
		padding-top: 90px;
	}

}


/* 11.3. Footer information
   ========================================================================== */

.footer-address {
	text-align: center;
}

.footer-phone {
	text-align: right;
}

@media only screen and (max-width: 999px) {

	.footer-email,
	.footer-phone {
		text-align: center;
	}

}


/* 11.4. Footer social
   ========================================================================== */

.footer-social-desktop-hidden {
	display: none;
}

@media only screen and (max-width: 549px) {

	.footer-social-desktop-hidden {
		display: block;
	}

}


/* 11.5. Footer copyright
   ========================================================================== */

.footer-copyright {
	width: calc(100% - 80px);
	max-width: 1350px;
}

@media only screen and (max-width: 999px) {

	.footer-copyright {
		width: calc(100% - 40px);
	}

}

@media only screen and (max-width: 549px) {

	.footer-copyright {
		width: calc(100% - 20px);
	}

}
`

export default Footer