import React, { useState } from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faInstagram, faFacebook, faYoutube} from "@fortawesome/free-brands-svg-icons"
import { Location } from "@reach/router"
import Logo from "../../images/amp-logo-transparent.png"

const Navbar = () => {
    const [isOpen, setNav] = useState(false)

    const toggleNav = () => {
      setNav(isOpen => !isOpen)
    }

    return (
    <NavbarWrapper>
        <div className="masthead flex-container">
        <a href="/#banner" className="nav-logo">
          <img src={Logo}/>
        </a>
        <button
          className={
            isOpen
              ? `${"toggle-btn"} ${"toggle-btn-active"}`
              : `${"toggle-btn"}`
          }
          type="button"
          onClick={toggleNav}
          aria-label="Menu Button"
        >
          <span></span>
          <span></span>
          <span></span>
        </button>
      </div>
      <div
        className={isOpen ? `${"nav-links"} ${"show-nav"} ${"flex-container"}` : `${"nav-links"}`}
      >
        <Location>
      {({location}) => {
      return(
        <ul className="links-list">
            <li className="nav_item--right serif">
              <a href="/churchlaunch" className={location.pathname === '/churchlaunch/' && `${"active"}`}>
                CHURCH LAUNCH
              </a>
            </li>
            <li className="nav_item--left serif">
            <a href="/smallgroups" className={location.pathname === '/smallgroups/' && `${"active"}`}>
              SMALL GROUPS
            </a>
            </li>
            <li className="nav_item--right serif">
            <a href="/teams" className={location.pathname === '/teams/' && `${"active"}`}>
              JOIN A TEAM
            </a>
            </li>
            <li className="nav_item--left serif">
              <a href="/donate" className={location.pathname === '/donate/' && `${"active"}`}>
                GIVE FINANCIALLY
              </a>
            </li>
         </ul>)}}
        </Location>
        <ul className="other-info">
          <li>
            Morning Prayer: <p className="body-text-xs">Wednesdays @ 6AM <br/></p>
          </li>
          <li>
            Service: <p className="body-text-xs">Sundays @ 11AM <br/></p>
            <p className="body-text-xs">Preservice Prayer @ 10:30AM</p>
          </li>
          <li>
            <a href="https://goo.gl/maps/kVZ1cG8MVBf9fAjT8" target="_blank" rel="noreferrer" aria-label="Google Maps Link">Address: <p className="body-text-xs" >1801 10 Ave SW<br/> Calgary, AB T3C 0K2</p></a>
          </li>
          <li className="list-socials">
            <a className="individual" aria-label="Instagram Link" rel="noreferrer" target="_blank" href="http://www.instagram.com/amplifiedyyc"><FontAwesomeIcon icon={faInstagram}/></a>
            <a className="individual" aria-label="Facebook Link" rel="noreferrer" target="_blank" href="http://www.youtube.com/channel/UCwyUjrDZkKoVhWzScHMrVgw"><FontAwesomeIcon icon={faFacebook}/></a>
            <a className="individual" aria-label="Youtube Link" rel="noreferrer" target="_blank" href="http://www.facebook.com/AmplifiedYYC"><FontAwesomeIcon icon={faYoutube}/></a>
          </li>
        </ul>
      </div>
    </NavbarWrapper>
    )
}

const NavbarWrapper = styled.div`
position: fixed;
z-index: 10;
top: 0;
width: 100%;
left: 0;
right: 0;
display: flex;
padding: 1rem;
box-sizing: border-box;

.under{
  text-decoration: underline!important;
}

.masthead {
  z-index: 3;
  width: 100%;
  justify-content: space-between;
  img {
    width: 100px;
    @media (min-width: 768px) {
      width: 120px;
    }
    @media (min-width: 1200px) {
      width: 140px;
    }
  }
}

.nav-links {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  background: linear-gradient(40deg, #EEE2DC, #fff);
  margin: 0;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transform: translateX(100%);
  transition: 0.3s ease-in;
  list-style: none;
  li {
    display: none;
    list-style: none;
    padding: 0.75rem 0;
    a {
      text-decoration: none;
      text-transform: capitalize;
      color: #000300;
      transform: translateX(100%);
      transition: 0.1s ease-in;
      &.active {
        color: #e85a4f;
      }
    }
    &:hover {
      cursor: pointer;
      a {
        color: #e85a4f;
      }
    }
  }
  &.show-nav {
    transform: translateX(0%);
    li {
      display: block;
    }
  }
}
.toggle-btn {
  width: 40px;
  height: 40px;
  padding: 5px;
  background-color: transparent;
  border: none;
  span {
    display: block;
    width: 30px;
    height: 2px;
    background-color: #000;
    transition: 0.2s ease-in;
    &:nth-child(1) {
      transform: translateY(-5px);
    }
    &:nth-child(3) {
      transform: translateY(5px);
    }
  }
  &.toggle-btn-active {
    span {
      &:nth-child(1) {
        transform: translateY(2px) rotate(45deg);
      }
      &:nth-child(2) {
        opacity: 0;
        transform: translateX(-100%);
      }
      &:nth-child(3) {
        transform: translateY(-2px) rotate(-45deg);
      }
    }
  }
}
.links-list{
  font-size: 4rem;
  font-weight: 400;
  margin: 5%;
  padding-top: 5%;
  @media only screen and (max-width: 850px){
    font-size: 3rem;
    margin: auto;
    margin-top: 10%;
    padding-top: 20%;
  }
  @media only screen and (max-width: 767px){
    font-size: 2rem;
    margin: auto;
    margin-top: 10%;
    padding-top: 20%;
  }
}
.list-socials{
  margin: 0 5%;
}
.individual{
  margin:5%;
  border-radius: 25px;
  &:hover{
    background-color: red;
  }
}
.other-info{
  font-size: 1rem;
  text-align: right;
  margin: 5%;
  padding-top: 5%;
  @media only screen and (max-width: 767px){
    font-size: 0.75rem;
    margin: auto;
    margin-bottom: 15%;
    padding-top: 0;
  }
}
.nav_item--left{
  margin-left: 20rem;
  @media only screen and (max-width: 767px){
    margin-left: 5rem;
  }
}
.nav_item--right{
  margin-right: 10rem;
  @media only screen and (max-width: 767px){
    margin-right: 5rem;
  }
}
`
export default Navbar