import React from 'react'
import styled from "styled-components"
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner"

const Loading = () => {
    return(
        <LoadWrapper>
            <div className="centered-load">
                <Loader
                type="Oval"
                color="#000"
                height={100}
                width={100}
                timeout={2000} //2 secs
                />
            </div>
        </LoadWrapper>
    )
}
const LoadWrapper = styled.div`
z-index: 100;
background-color: #f8f4f1;
height: 100vh;
width: 100%;
position: fixed;

.centered-load{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
`
export default Loading