import React, { useState, useEffect } from "react"
import styled from "styled-components"
import Navbar from "./Shared/navbar"
import Footer from "./Shared/footer"
import Socialbar from "./Shared/socialbar"
import Loading from "./Shared/loading"
import SEO from "./Shared/seo"
import 'bootstrap/dist/css/bootstrap.min.css' //use bootstrap
import "@fontsource/montserrat" //imported google font
import "@fontsource/oranienbaum" //imported google font
import "@fontsource/suranna" //imported google font

const Layout = ({ children }) => {
	const [isLoading, setIsLoading] = useState(true)

	useEffect(() => {
		setTimeout(() => {
			setIsLoading(false)
		}, 1000)
	})
    return (
        <LayoutWrapper>
			<SEO 
			title="Amplified YYC"
			description="LOVE GOD LOVE PEOPLE SERVE PEOPLE. Amplified is a local church based in Calgary AB, Canada. A multi-generational, multi-ethnic church that is focused on sharing the love of Jesus with this city. We would love for you to come and be a part."
			/>
			{ isLoading &&
			<Loading /> }
            <Navbar />
			<Socialbar />
            <div className="body">{children}</div>
            <Footer /> 
        </LayoutWrapper>
    )
}

const LayoutWrapper = styled.div`
/* ==========================================================================
   32. Colors: 
   red #e85a4f 
   white #eee2dc 
   blue #123c69
   cream #edc787 
   ========================================================================== */
height: 100%;
width: 100%;

::-moz-selection {
	background: none;
	color: #6b131e ;
}

::selection {
	background: none;
	color: #6b131e ;
}

a {
	text-decoration: none;
}

img {
	border-radius: 25px;
}

.body {
	background-color: #f8f4f1;
	color: #f8f7f9;
	z-index: 2;
	position: relative;
	margin-bottom: 80vh;
	padding-bottom: 3rem;
	@media only screen and (max-width: 767px){
		margin-bottom: 0;
	}
}
.center{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 75%;
}

.button{
	font-size: 13px;
	text-align: center;
    color: #fff;
	padding: 12px 60px;
	box-shadow: 2px 5px 10px rgba(0,0,0,.1);
	background-color: #e85a4f;
	border-radius: 6px;
	letter-spacing: 1.5px;
	&:hover{
		background-color: black;
	}
}

/**** FORMS *****/

.teams-headline{
	min-height: 20vh;
	text-align: center;
	padding-bottom: 8%;
  }
  
  form {
	  position: relative;
	  overflow: hidden;
	  width: 100%;
	  margin: 0 15%;
	  @media only screen and (max-width: 767px){
		margin: 0 5%;
	  }
	  .input-area {
		margin-bottom: 40px;
		position: relative;
		&.button-area {
		  text-align: center;
		  margin-bottom: 0;
		}
	  }
	  input,
	  select,
	  textarea {
		height: 100%;
		font-size: 1rem;
		letter-spacing: 0.25rem;
		padding: 20px;
		display: block;
		width: 100% !important;
		border: none;
		border-radius: 10px;
		background-color: black;
		color: white;
		/* text-transform: uppercase; */
		position: relative;
		box-sizing: border-box;
		outline: none;
		&:focus,
		&:valid {
		  + .label-name {
			.content-name {
			  transform: translateY(-25%);
			  font-size: 0.7rem;
			  opacity: 0.2;
			}
			&::after {
			  transform: translateX(0%);
			}
		  }
		}
	  }
	  label {
		  position: absolute;
		  top: 0px;
		  left: 0px;
		  width: 100%;
		  height: 100%;
		  pointer-events: none;
		  &::after {
			content: "";
			position: absolute;
			left: 0px;
			bottom: -1px;
			height: 1px;
			background: linear-gradient(90deg, #006635, #000300);
			width: 100%;
			transform: translateX(-100%);
			transition: transform 0.3s ease;
		  }
		}
		.content-name {
		  position: absolute;
		  top: 10px;
		  left: 20px;
		  transition: all 0.3s ease;
		  text-transform: uppercase;
		  letter-spacing: 0.25rem;
		  font-size: 0.8rem;
		}
	  }
	}
  }
  
/* 32.1. Background colors
   ========================================================================== */

.section-bg-black,
.content-bg-black,
.text-bg-black {
	background-color: #000;
}

.section-bg-white,
.content-bg-white,
.text-bg-white {
	background-color: #f8f7f9;
}

.section-bg-light-1,
.content-bg-light-1,
.text-bg-light-1 {
	background-color: var(--bg-light-1);
}

.section-bg-light-2,
.content-bg-light-2,
.text-bg-light-2 {
	background-color: var(--bg-light-2);
}

.section-bg-dark-1,
.content-bg-dark-1,
.text-bg-dark-1 {
	background-color: var(--bg-dark-1);
}

.section-bg-dark-2,
.content-bg-dark-2,
.text-bg-dark-2 {
	background-color: var(--bg-dark-2);
}

.content-bg-red,
.text-bg-red {
	background-color: #e85a4f;
}


/* 32.2. Text colors
   ========================================================================== */

.text-color-white {
	color: #fff;
}

.text-color-white:before {
	color: #123C69 !important;
}

.text-color-blue {
	color: #123c69;
}

.text-color-dadada:before {
	color: #dadada !important;
}

.text-color-b0b0b0 {
	color: #b0b0b0;
}

.text-color-b0b0b0:before {
	color: #b0b0b0 !important;
}

.text-color-8a8a8a {
	color: #8a8a8a;
}

.text-color-8a8a8a:before {
	color: #8a8a8a !important;
}

.text-color-e4e4e4 {
	color: #e4e4e4;
}

.text-color-e4e4e4:before {
	color: #e4e4e4 !important;
}

.text-color-bbbbbb {
	color: #bbbbbb;
}

.text-color-bbbbbb:before {
	color: #bbbbbb !important;
}

.text-color-979797 {
	color: #979797;
}

.text-color-979797:before {
	color: #979797 !important;
}

.text-color-black {
	color: #000;
}

.text-color-black:before {
	color: #000 !important;
}

.text-color-6d6d6d {
	color: #6d6d6d;
}

.text-color-6d6d6d:before {
	color: #6d6d6d !important;
}

.text-color-888888 {
	color: #888888;
}

.text-color-888888:before {
	color: #888888 !important;
}

.text-color-9b9b9b {
	color: #9b9b9b;
}

.text-color-9b9b9b:before {
	color: #9b9b9b !important;
}

.text-color-6a6a6a {
	color: #6a6a6a;
}

.text-color-6a6a6a:before {
	color: #6a6a6a !important;
}

.text-color-838383 {
	color: #838383;
}

.text-color-838383:before {
	color: #838383 !important;
}

.text-color-969696 {
	color: #969696;
}

.text-color-969696:before {
	color: #969696 !important;
}

.text-color-red {
	color: #e85a4f;
}

.text-color-red:before {
	color: #e85a4f !important;
}


/* 32.3. Text hover colors
   ========================================================================== */

.text-hover-to-red:hover {
	color: #6b131e  !important;
	-webkit-transition: 1s cubic-bezier(.17, .85, .438, .99);
	     -o-transition: 1s cubic-bezier(.17, .85, .438, .99);
	        transition: 1s cubic-bezier(.17, .85, .438, .99);
}

.text-hover-to-white:hover {
	color: #f8f7f9 !important;
	-webkit-transition: 1s cubic-bezier(.17, .85, .438, .99);
	     -o-transition: 1s cubic-bezier(.17, .85, .438, .99);
	        transition: 1s cubic-bezier(.17, .85, .438, .99);
}

.text-hover-to-black:hover {
	color: #000 !important;
	-webkit-transition: 1s cubic-bezier(.17, .85, .438, .99);
	     -o-transition: 1s cubic-bezier(.17, .85, .438, .99);
	        transition: 1s cubic-bezier(.17, .85, .438, .99);
}


/* 32.4. Main colors
   ========================================================================== */

/* Background colors */
:root {
	--bg-light-1: #f5f5f5;
}

:root {
	--bg-light-2: #f0f0f0;
}

:root {
	--bg-dark-1: #111517;
}

:root {
	--bg-dark-2: #1b2a2f;
}

/* Red colors */
:root {
	--main-color: #d20120;
}


/**********************/

html {
	margin:  0;
	padding: 0;
}

blockquote,
ul,
ol {
	margin:  0;
	padding: 0;
    list-style-type: circle;
}

ul li,
ol li {
	margin-bottom: 0;
}

li {
	margin:  0;
	padding: 0;
}

img {
	width:     100%;
	max-width: 100%;
	vertical-align: middle;
}

a,
i {
	border: none;
}

button {
	padding: 0;
}

a {
	color: inherit;
}

a:hover {
	text-decoration: none;
}

button,
button:focus,
button:hover {
	border:     none;
	background: none;
}

a:focus,
button:focus {
	outline: none;
}

table {
	border-spacing: 0;
	border-collapse: collapse;
}

td,
th {
	padding: 5px;
	border: 1px solid #000;
	text-align: center;
}

.hidden-box {
	overflow: hidden;
}

.border-radius-50perc {
	border-radius: 50%;
}

.border-radius-10px {
	border-radius: 10px;
}
.width-100perc {
	width: 100%;
}

.height-100perc {
	height: 100%;
}

.height-100vh {
	height: 100vh;
}


/* 2.3. Z-index
   ========================================================================== */

.zindex5 {
	z-index: 5;
}


/* 2.4. Background cover
   ========================================================================== */

.bg-img-cover,
.bg-img-cover-fixed {
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
}

.js-no-touch .bg-img-cover-fixed {
	background-attachment: fixed;
}
/* ==========================================================================
   29. Background overlay
   ========================================================================== */

.bg-overlay-black,
.bg-overlay-white {
	width:  100%;
	height: 100%;
	position: absolute;
	left: 0;
	top:  0;
	opacity: .47;
}

.bg-overlay-black.bg-overlay-black_02,
.bg-overlay-white.bg-overlay-white_02 {
	opacity: .2;
}

.bg-overlay-black {
	background-color: #000;
}

.bg-overlay-white {
	background-color: #eee2dc;
}
.bg-overlay-blue {
	background-color: #123c69;
}


/* 2.5. Display settings
   ========================================================================== */

.d-inline-block {
	display: inline-block;
}

.d-block {
	display: block;
}

.d-flex {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

.flex-center-center {
	-webkit-box-align: center;
	   -ms-flex-align: center;
	      align-items: center;
	-webkit-box-pack: center;
	   -ms-flex-pack: center;
	 justify-content: center;
}

.flex-align-center {
	-webkit-box-align: center;
	   -ms-flex-align: center;
	      align-items: center;
}

.flex-align-end {
	-webkit-box-align: end;
	   -ms-flex-align: end;
	      align-items: flex-end;
}

.flex-justify-center {
	-webkit-box-pack: center;
	   -ms-flex-pack: center;
	 justify-content: center;
}


/* 2.6. Paddings & margins
   ========================================================================== */

/* Paddings */
.padding-top-bottom-150 {
	padding-top:    150px;
	padding-bottom: 150px;
}

.padding-top-150 {
	padding-top: 150px;
}

.padding-bottom-150 {
	padding-bottom: 150px;
}

.padding-top-bottom-120 {
	padding-top:    120px;
	padding-bottom: 120px;
}

.padding-top-120 {
	padding-top: 120px;
}

.padding-bottom-120 {
	padding-bottom: 120px;
}

.padding-top-bottom-100 {
	padding-top:    100px;
	padding-bottom: 100px;
}

.padding-top-100 {
	padding-top: 100px;
}

.padding-bottom-100 {
	padding-bottom: 100px;
}

.padding-top-bottom-90 {
	padding-top:    90px;
	padding-bottom: 90px;
}

.padding-top-90 {
	padding-top: 90px;
}

.padding-bottom-90 {
	padding-bottom: 90px;
}

.padding-top-bottom-80 {
	padding-top:    80px;
	padding-bottom: 80px;
}

.padding-top-80 {
	padding-top: 80px;
}

.padding-bottom-80 {
	padding-bottom: 80px;
}

.padding-top-bottom-70 {
	padding-top:    70px;
	padding-bottom: 70px;
}

.padding-top-70 {
	padding-top: 70px;
}

.padding-bottom-70 {
	padding-bottom: 70px;
}

.padding-top-bottom-60 {
	padding-top:    60px;
	padding-bottom: 60px;
}

.padding-top-60 {
	padding-top: 60px;
}

.padding-bottom-60 {
	padding-bottom: 60px;
}

.padding-top-bottom-50 {
	padding-top:    50px;
	padding-bottom: 50px;
}

.padding-top-50 {
	padding-top: 50px;
}

.padding-bottom-50 {
	padding-bottom: 50px;
}

.padding-top-bottom-40 {
	padding-top:    40px;
	padding-bottom: 40px;
}

.padding-top-40 {
	padding-top: 40px;
}

.padding-bottom-40 {
	padding-bottom: 40px;
}

.padding-top-bottom-30 {
	padding-top:    30px;
	padding-bottom: 30px;
}

.padding-top-30 {
	padding-top: 30px;
}

.padding-bottom-30 {
	padding-bottom: 30px;
}

.padding-top-bottom-20 {
	padding-top:    20px;
	padding-bottom: 20px;
}

.padding-top-20 {
	padding-top: 20px;
}

.padding-bottom-20 {
	padding-bottom: 20px;
}

.padding-top-bottom-10 {
	padding-top:    10px;
	padding-bottom: 10px;
}

.padding-top-10 {
	padding-top: 10px;
}

.padding-bottom-10 {
	padding-bottom: 10px;
}

.padding-top-bottom-5 {
	padding-top:    5px;
	padding-bottom: 5px;
}

.padding-top-5 {
	padding-top: 5px;
}

.padding-bottom-5 {
	padding-bottom: 5px;
}

.padding-top-bottom-1 {
	padding-top:    1px;
	padding-bottom: 1px;
}

.padding-top-1 {
	padding-top: 1px;
}

.padding-bottom-1 {
	padding-bottom: 1px;
}

.padding-left-right-50 {
	padding-left:  50px;
	padding-right: 50px;
}

.padding-left-50 {
	padding-left: 50px;
}

.padding-right-50 {
	padding-right: 50px;
}

.padding-left-right-40 {
	padding-left:  40px;
	padding-right: 40px;
}

.padding-left-40 {
	padding-left: 40px;
}

.padding-right-40 {
	padding-right: 40px;
}

.padding-left-right-30 {
	padding-left:  30px;
	padding-right: 30px;
}

.padding-left-30 {
	padding-left: 30px;
}

.padding-right-30 {
	padding-right: 30px;
}

.padding-left-right-20 {
	padding-left:  20px;
	padding-right: 20px;
}

.padding-left-20 {
	padding-left: 20px;
}

.padding-right-20 {
	padding-right: 20px;
}

.padding-20 {
	padding: 20px;
}

/* Margins */
.margin-top-bottom-60 {
	margin-top:    60px;
	margin-bottom: 60px;
}

.margin-top-60 {
	margin-top: 60px;
}

.margin-bottom-60 {
	margin-bottom: 60px;
}

.margin-top-bottom-50 {
	margin-top:    50px;
	margin-bottom: 50px;
}

.margin-top-50 {
	margin-top: 50px;
}

.margin-bottom-50 {
	margin-bottom: 50px;
}

.margin-top-bottom-40 {
	margin-top:    40px;
	margin-bottom: 40px;
}

.margin-top-40 {
	margin-top: 40px;
}

.margin-bottom-40 {
	margin-bottom: 40px;
}

.margin-top-bottom-30 {
	margin-top:    30px;
	margin-bottom: 30px;
}

.margin-top-30 {
	margin-top: 30px;
}

.margin-bottom-30 {
	margin-bottom: 30px;
}

.margin-top-bottom-20 {
	margin-top:    20px;
	margin-bottom: 20px;
}

.margin-top-20 {
	margin-top: 20px;
}

.margin-bottom-20 {
	margin-bottom: 20px;
}

.margin-top-bottom-10 {
	margin-top:    10px;
	margin-bottom: 10px;
}

.margin-top-10 {
	margin-top: 10px;
}

.margin-bottom-10 {
	margin-bottom: 10px;
}

.margin-top-bottom-5 {
	margin-top:    5px;
	margin-bottom: 5px;
}

.margin-top-5 {
	margin-top: 5px;
}

.margin-bottom-5 {
	margin-bottom: 5px;
}

.margin-top-bottom-1 {
	margin-top:    1px;
	margin-bottom: 1px;
}

.margin-top-1 {
	margin-top: 1px;
}

.margin-bottom-1 {
	margin-bottom: 1px;
}

.margin-left-right-50 {
	margin-left:  50px;
	margin-right: 50px;
}

.margin-left-50 {
	margin-left: 50px;
}

.margin-right-50 {
	margin-right: 50px;
}

.margin-left-right-40 {
	margin-left:  40px;
	margin-right: 40px;
}

.margin-left-40 {
	margin-left: 40px;
}

.margin-right-40 {
	margin-right: 40px;
}

.margin-left-right-30 {
	margin-left:  30px;
	margin-right: 30px;
}

.margin-left-30 {
	margin-left: 30px;
}

.margin-right-30 {
	margin-right: 30px;
}

.margin-left-right-20 {
	margin-left:  20px;
	margin-right: 20px;
}

.margin-left-20 {
	margin-left: 20px;
}

.margin-right-20 {
	margin-right: 20px;
}

.margin-left-right-10 {
	margin-left:  10px;
	margin-right: 10px;
}

.margin-left-10 {
	margin-left: 10px;
}

.margin-right-10 {
	margin-right: 10px;
}

.margin-20 {
	margin: 20px;
}

/* price top offset */
.price-top-offset {
	margin-top: 20px;
}

.price-btn-offset {
	padding: 50px 0;
}

@media only screen and (max-width: 767px) {

	.price-top-offset {
		margin-top: 0;
	}

	.price-btn-offset {
		padding: 0 0 50px 0;
	}

}

/* portfolio top offset */
.portfolio-top-offset {
	margin-top: 120px;
}

@media only screen and (max-width: 767px) {

	.portfolio-top-offset {
		margin-top: 0;
	}

}


/* 2.7. Positions
   ========================================================================== */

.pos-rel {
	position: relative;
}

.pos-abs {
	position: absolute;
}

.pos-static {
	position: static !important;
}

.pos-left-top {
	left: 20px;
	top:  20px;
}

.pos-left-center {
	left: 20px;
	top: 50%;
	-webkit-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	        transform: translateY(-50%);
}

.pos-left-bottom {
	left:   20px;
	bottom: 20px;
}

.pos-right-top {
	top:   20px;
	right: 20px;
}

.pos-right-center {
	top: 50%;
	right: 20px;
	-webkit-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	        transform: translateY(-50%);
}

.pos-right-bottom {
	right:  20px;
	bottom: 20px;
}

.pos-top-center {
	left: 50%;
	top: 20px;
	-webkit-transform: translateX(-50%);
	    -ms-transform: translateX(-50%);
	        transform: translateX(-50%);
}

.pos-bottom-center {
	left: 50%;
	bottom: 20px;
	-webkit-transform: translateX(-50%);
	    -ms-transform: translateX(-50%);
	        transform: translateX(-50%);
}

.pos-center-center {
	left: 50%;
	top:  50%;
	-webkit-transform: translate(-50%, -50%);
	    -ms-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
}

/* Positions 0px */
.pos-left-top-0px {
	left: 0;
	top:  0;
}

.pos-left-center-0px {
	left: 0;
	top: 50%;
	-webkit-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	        transform: translateY(-50%);
}

.pos-left-bottom-0px {
	left:   0;
	bottom: 0;
}

.pos-right-top-0px {
	top:   0;
	right: 0;
}

.pos-right-center-0px {
	top: 50%;
	right: 0;
	-webkit-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	        transform: translateY(-50%);
}

.pos-right-bottom-0px {
	right:  0;
	bottom: 0;
}

.pos-top-center-0px {
	left: 50%;
	top: 0;
	-webkit-transform: translateX(-50%);
	    -ms-transform: translateX(-50%);
	        transform: translateX(-50%);
}

.pos-bottom-center-0px {
	left: 50%;
	bottom: 0;
	-webkit-transform: translateX(-50%);
	    -ms-transform: translateX(-50%);
	        transform: translateX(-50%);
}


/* 2.8. Borders
   ========================================================================== */

.border-box {
	position: relative;
	z-index: 5;
	-webkit-box-sizing: border-box;
	        box-sizing: border-box;
	border: 1px solid rgba(250, 250, 250, .1);
}

.border-box.black {
	border: 1px solid rgba(0, 0, 0, .1);
}

.border-box-bottom {
	-webkit-box-sizing: border-box;
	        box-sizing: border-box;
	border-bottom: 1px solid rgba(250, 250, 250, .1);
}

.border-box-bottom.black {
	border-bottom: 1px solid rgba(0, 0, 0, .1);
}

.border-box-l-r {
	z-index: 6;
	-webkit-box-sizing: border-box;
	        box-sizing: border-box;
	border-left:  1px solid #fff;
	border-right: 1px solid #fff;
}

.border-box-l-r.black {
	border-left:  1px solid #000;
	border-right: 1px solid #000;
}


/* 2.9. Parallax background
   ========================================================================== */

.js-parallax-bg {
	background-size: cover;
	background-position: top center;
	background-repeat: no-repeat;
	-webkit-transition: initial;
	     -o-transition: initial;
	        transition: initial;
}


/* 2.10. Video background
   ========================================================================== */

.video-bg {
	min-width:  100%;
	min-height: 100%;
	position: absolute;
	left: 50%;
	top:  50%;
	-webkit-transform: translate(-50%, -50%);
	    -ms-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
}





/* ==========================================================================
   3. Container width
   ========================================================================== */

.container {
	width: calc(100% - 80px);
	max-width: 1350px;
	margin-left:  auto;
	margin-right: auto;
}

.container.full {
	max-width: 100%;
}

.container.small {
	max-width: 960px;
}

@media only screen and (max-width: 999px) {

	.container {
		width: calc(100% - 40px);
	}

}

@media only screen and (max-width: 549px) {

	.container {
		width: calc(100% - 20px);
	}

}





/* ==========================================================================
   4. Flex grid
   ========================================================================== */

.flex-container {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-flow: wrap;
	    flex-flow: wrap;
}

.flex-container.reverse {
	-webkit-box-orient: vertical;
	-webkit-box-direction: reverse;
	    -ms-flex-flow: wrap-reverse;
	        flex-flow: wrap-reverse;
}

.one-column,
.two-columns,
.three-columns,
.four-columns,
.five-columns,
.six-columns,
.seven-columns,
.eight-columns,
.nine-columns,
.ten-columns,
.eleven-columns,
.twelve-columns {
	-webkit-box-sizing: border-box;
	        box-sizing: border-box;
}

.one-column {
	-ms-flex-preferred-size: 8.33%;
	             flex-basis: 8.33%;
}

.two-columns {
	-ms-flex-preferred-size: 16.66%;
	             flex-basis: 16.66%;
}

.three-columns {
	-ms-flex-preferred-size: 25%;
	             flex-basis: 25%;
}

.four-columns {
	-ms-flex-preferred-size: 33.33%;
	             flex-basis: 33.33%;
}

.five-columns {
	-ms-flex-preferred-size: 41.66%;
	             flex-basis: 41.66%;
}

.six-columns {
	-ms-flex-preferred-size: 50%;
	             flex-basis: 50%;
}

.seven-columns {
	-ms-flex-preferred-size: 58.33%;
	             flex-basis: 58.33%;
}

.eight-columns {
	-ms-flex-preferred-size: 66.66%;
	             flex-basis: 66.66%;
}

.nine-columns {
	-ms-flex-preferred-size: 75%;
	             flex-basis: 75%;
}

.ten-columns {
	-ms-flex-preferred-size: 83.33%;
	             flex-basis: 83.33%;
}

.eleven-columns {
	-ms-flex-preferred-size: 91.66%;
	             flex-basis: 91.66%;
}

.twelve-columns {
	-ms-flex-preferred-size: 100%;
	             flex-basis: 100%;
}

.one-offset {
	margin-left: 8.33%;
}

.two-offset {
	margin-left: 16.66%;
}

.three-offset {
	margin-left: 25%;
}

.four-offset {
	margin-left: 33.33%;
}

.five-offset {
	margin-left: 41.66%;
}

.six-offset {
	margin-left: 50%;
}

.seven-offset {
	margin-left: 58.33%;
}

.eight-offset {
	margin-left: 66.66%;
}

.nine-offset {
	margin-left: 75%;
}

.ten-offset {
	margin-left: 83.33%;
}

.eleven-offset {
	margin-left: 91.66%;
}

@media only screen and (max-width: 999px) {

	.column-100-100 {
		-ms-flex-preferred-size: 100% !important;
		             flex-basis: 100% !important;
		margin-left: 0 !important;
	}

	.column-50-100 {
		-ms-flex-preferred-size: 50% !important;
		             flex-basis: 50% !important;
		margin-left: 0 !important;
	}

}

@media only screen and (max-width: 767px) {

	.one-column,
	.two-columns,
	.three-columns,
	.four-columns,
	.five-columns,
	.six-columns,
	.seven-columns,
	.eight-columns,
	.nine-columns,
	.ten-columns,
	.eleven-columns,
	.twelve-columns {
		-ms-flex-preferred-size: 100%;
		             flex-basis: 100%;
	}

	.one-offset,
	.two-offset,
	.three-offset,
	.four-offset,
	.five-offset,
	.six-offset,
	.seven-offset,
	.eight-offset,
	.nine-offset,
	.ten-offset,
	.eleven-offset {
		margin-left: 0;
	}

	.column-50-100 {
		-ms-flex-preferred-size: 100% !important;
		             flex-basis: 100% !important;
	}

}


/* 4.1. Flex column margins
   ========================================================================== */

.column-l-r-margin-40 {
	margin-left:  40px;
	margin-right: 40px;
}

.column-r-margin-40 {
	margin-right: 40px;
}

.column-l-margin-40 {
	margin-left: 40px;
}

.column-l-r-margin-20 {
	margin-left:  20px;
	margin-right: 20px;
}

.column-r-margin-20 {
	margin-right: 20px;
}

.column-l-margin-20 {
	margin-left: 20px;
}

.column-l-r-margin-10 {
	margin-left:  10px;
	margin-right: 10px;
}

.column-r-margin-10 {
	margin-right: 10px;
}

.column-l-margin-10 {
	margin-left: 10px;
}

.column-l-r-margin-5 {
	margin-left:  5px;
	margin-right: 5px;
}

.column-r-margin-5 {
	margin-right: 5px;
}

.column-l-margin-5 {
	margin-left: 5px;
}

.line-col-r-margin-20 {
	margin-right: 20px;
}

.line-col-l-r-margin-20 {
	margin-left:  20px;
	margin-right: 20px;
}

.column-l-margin-40-999 {
	margin-left: 40px;
}

.column-r-margin-40-999 {
	margin-right: 40px;
}

.column-l-margin-20-999 {
	margin-left: 20px;
}

.column-r-margin-20-999 {
	margin-right: 20px;
}

.column-l-r-margin-10-999 {
	margin-left:  10px;
	margin-right: 10px;
}

@media only screen and (max-width: 999px) {

	.column-l-margin-40-999 {
		margin-left: 0;
	}

	.column-r-margin-40-999 {
		margin-right: 0;
	}

	.column-l-margin-20-999 {
		margin-left: 0;
	}

	.column-r-margin-20-999 {
		margin-right: 0;
	}

	.column-l-r-margin-10-999 {
		margin-left:  0;
		margin-right: 0;
	}

}

@media only screen and (max-width: 767px) {

	.column-l-r-margin-40,
	.column-l-r-margin-20,
	.column-l-r-margin-10,
	.column-l-r-margin-5 {
		margin-left:  0;
		margin-right: 0;
	}

	.column-r-margin-40,
	.column-r-margin-20,
	.column-r-margin-10,
	.column-r-margin-5 {
		margin-right: 0;
	}

	.column-l-margin-40,
	.column-l-margin-20,
	.column-l-margin-10,
	.column-l-margin-5 {
		margin-left: 0;
	}

	.line-col-r-margin-20 {
		margin-right: 0;
	}

	.line-col-l-r-margin-20 {
		margin-left:  0;
		margin-right: 0;
	}

	.lines-section .line-col-r-margin-20 {
		margin-right: 20px;
	}

	.lines-section .line-col-l-r-margin-20 {
		margin-left:  20px;
		margin-right: 20px;
	}

}

@media only screen and (max-width: 549px) {

	.lines-section .line-col-r-margin-20 {
		margin-right: 0;
	}

	.lines-section .line-col-l-r-margin-20 {
		margin-left:  0;
		margin-right: 0;
	}

}





/* ==========================================================================
   5. Min-height flex center
   ========================================================================== */

.flex-min-height-100vh {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	   -ms-flex-align: center;
	      align-items: center;
	width:  100%;
	height: 100%;
	min-height: 100vh;
}

.serif{
	font-family: 'Suranna', serif;
}
.headline-xxxxl {
	font-family: 'Suranna', serif;
	font-size: 94px;
	font-weight: 300;
	line-height: 1.2;
}

.headline-xxxl {
	font-family: 'Suranna', sans-serif;
	font-size: 86px;
	font-weight: 300;
	line-height: 1.2;
}

.headline-xxl {
	font-family: 'Suranna', sans-serif;
	font-size: 78px;
	font-weight: 300;
	line-height: 1.2;
}

.headline-xl {
	font-family: 'Suranna', sans-serif;
	font-size: 70px;
	font-weight: 300;
	line-height: 1.2;
}

.headline-l {
	font-family: 'Suranna', sans-serif;
	font-size: 62px;
	font-weight: 300;
	line-height: 1.2;
}

.headline-m {
	font-family: 'Suranna', sans-serif;
	font-size: 54px;
	font-weight: 300;
	line-height: 1.2;
}

.headline-s {
	font-family: 'Suranna', sans-serif;
	font-size: 46px;
	font-weight: 300;
	line-height: 1.2;
}

.headline-xs {
	font-family: 'Suranna', sans-serif;
	font-size: 38px;
	font-weight: 300;
	line-height: 1.2;
}

.headline-xxs {
	font-family: 'Suranna', sans-serif;
	font-size: 30px;
	font-weight: 300;
	line-height: 1.2;
}

.headline-xxxs {
	font-family: 'Suranna', sans-serif;
	font-size: 22px;
	font-weight: 300;
	line-height: 1.2;
}

.headline-xxxxs {
	font-family: 'Suranna', sans-serif;
	font-size: 14px;
	font-weight: 300;
	line-height: 1.2;
}

@media only screen and (max-width: 1199px) {

	.headline-xxxxl {
		font-size: 86.5px;
	}

	.headline-xxxl {
		font-size: 79.25px;
	}

	.headline-xxl {
		font-size: 72px;
	}

	.headline-xl {
		font-size: 64.75px;
	}

	.headline-l {
		font-size: 57.5px;
	}

	.headline-m {
		font-size: 50.25px;
	}

	.headline-s {
		font-size: 43px;
	}

	.headline-xs {
		font-size: 35.75px;
	}

	.headline-xxs {
		font-size: 28.5px;
	}

	.headline-xxxs {
		font-size: 21.25px;
	}

}

@media only screen and (max-width: 999px) {

	.headline-xxxxl {
		font-size: 79px;
	}

	.headline-xxxl {
		font-size: 72.5px;
	}

	.headline-xxl {
		font-size: 66px;
	}

	.headline-xl {
		font-size: 59.5px;
	}

	.headline-l {
		font-size: 53px;
	}

	.headline-m {
		font-size: 46.5px;
	}

	.headline-s {
		font-size: 40px;
	}

	.headline-xs {
		font-size: 33.5px;
	}

	.headline-xxs {
		font-size: 27px;
	}

	.headline-xxxs {
		font-size: 20.5px;
	}

}

@media only screen and (max-width: 767px) {

	.headline-xxxxl {
		font-size: 71.5px;
	}

	.headline-xxxl {
		font-size: 65.75px;
	}

	.headline-xxl {
		font-size: 60px;
	}

	.headline-xl {
		font-size: 54.25px;
	}

	.headline-l {
		font-size: 48.5px;
	}

	.headline-m {
		font-size: 42.75px;
	}

	.headline-s {
		font-size: 37px;
	}

	.headline-xs {
		font-size: 31.25px;
	}

	.headline-xxs {
		font-size: 25.5px;
	}

	.headline-xxxs {
		font-size: 19.75px;
	}

}

@media only screen and (max-width: 549px) {

	.headline-xxxxl {
		font-size: 64px;
	}

	.headline-xxxl {
		font-size: 59px;
	}

	.headline-xxl {
		font-size: 54px;
	}

	.headline-xl {
		font-size: 49px;
	}

	.headline-l {
		font-size: 44px;
	}

	.headline-m {
		font-size: 39px;
	}

	.headline-s {
		font-size: 34px;
	}

	.headline-xs {
		font-size: 29px;
	}

	.headline-xxs {
		font-size: 24px;
	}

	.headline-xxxs {
		font-size: 19px;
	}

}

@media only screen and (max-width: 399px) {

	.headline-xxxxl {
		font-size: 56.5px;
	}

	.headline-xxxl {
		font-size: 52.25px;
	}

	.headline-xxl {
		font-size: 48px;
	}

	.headline-xl {
		font-size: 43.75px;
	}

	.headline-l {
		font-size: 39.5px;
	}

	.headline-m {
		font-size: 35.25px;
	}

	.headline-s {
		font-size: 31px;
	}

	.headline-xs {
		font-size: 26.75px;
	}

	.headline-xxs {
		font-size: 22.5px;
	}

	.headline-xxxs {
		font-size: 18.25px;
	}

}


/* 18.2. Subhead
   ========================================================================== */

.subhead-xxl {
	font-family: 'Montserrat', sans-serif;
	font-size: 33px;
	font-weight: 400;
	line-height: 1.4;
}

.subhead-xl {
	font-family: 'Montserrat', sans-serif;
	font-size: 30px;
	font-weight: 400;
	line-height: 1.4;
}

.subhead-l {
	font-family: 'Montserrat', sans-serif;
	font-size: 27px;
	font-weight: 400;
	line-height: 1.4;
}

.subhead-m {
	font-family: 'Montserrat', sans-serif;
	font-size: 24px;
	font-weight: 400;
	line-height: 1.4;
}

.subhead-s {
	font-family: 'Montserrat', sans-serif;
	font-size: 21px;
	font-weight: 400;
	line-height: 1.4;
}

.subhead-xs {
	font-family: 'Montserrat', sans-serif;
	font-size: 18px;
	font-weight: 400;
	line-height: 1.4;
}

.subhead-xxs {
	font-family: 'Montserrat', sans-serif;
	font-size: 15px;
	font-weight: 400;
	line-height: 1.4;
}

@media only screen and (max-width: 1199px) {

	.subhead-xxl {
		font-size: 31.5px;
	}

	.subhead-xl {
		font-size: 28.75px;
	}

	.subhead-l {
		font-size: 26px;
	}

	.subhead-m {
		font-size: 23.25px;
	}

	.subhead-s {
		font-size: 20.5px;
	}

	.subhead-xs {
		font-size: 17.75px;
	}

}

@media only screen and (max-width: 999px) {

	.subhead-xxl {
		font-size: 30px;
	}

	.subhead-xl {
		font-size: 27.5px;
	}

	.subhead-l {
		font-size: 25px;
	}

	.subhead-m {
		font-size: 22.5px;
	}

	.subhead-s {
		font-size: 20px;
	}

	.subhead-xs {
		font-size: 17.5px;
	}

}

@media only screen and (max-width: 767px) {

	.subhead-xxl {
		font-size: 28.5px;
	}

	.subhead-xl {
		font-size: 26.25px;
	}

	.subhead-l {
		font-size: 24px;
	}

	.subhead-m {
		font-size: 21.75px;
	}

	.subhead-s {
		font-size: 19.5px;
	}

	.subhead-xs {
		font-size: 17.25px;
	}

}

@media only screen and (max-width: 549px) {

	.subhead-xxl {
		font-size: 27px;
	}

	.subhead-xl {
		font-size: 25px;
	}

	.subhead-l {
		font-size: 23px;
	}

	.subhead-m {
		font-size: 21px;
	}

	.subhead-s {
		font-size: 19px;
	}

	.subhead-xs {
		font-size: 17px;
	}

}

@media only screen and (max-width: 399px) {

	.subhead-xxl {
		font-size: 25.5px;
	}

	.subhead-xl {
		font-size: 23.75px;
	}

	.subhead-l {
		font-size: 22px;
	}

	.subhead-m {
		font-size: 20.25px;
	}

	.subhead-s {
		font-size: 18.5px;
	}

	.subhead-xs {
		font-size: 16.75px;
	}

}


/* 18.3. Body text
   ========================================================================== */

.body-text-xl {
	font-family: 'Montserrat', sans-serif;
	font-size: 21px;
	font-weight: 200;
	line-height: 1.9;
}

.body-text-l {
	font-family: 'Montserrat', sans-serif;
	font-size: 19px;
	font-weight: 200;
	line-height: 1.9;
}

.body-text-m {
	font-family: 'Montserrat', sans-serif;
	font-size: 17px;
	font-weight: 200;
	line-height: 1.9;
}

.body-text-s {
	font-family: 'Montserrat', sans-serif;
	font-size: 15px;
	font-weight: 200;
	line-height: 1.9;
}

.body-text-xs {
	font-family: 'Montserrat', sans-serif;
	font-size: 13px;
	font-weight: 200;
	line-height: 1.9;
}

@media only screen and (max-width: 1199px) {

	.body-text-xl {
		font-size: 20.5px;
	}

	.body-text-l {
		font-size: 18.625px;
	}

	.body-text-m {
		font-size: 16.75px;
	}

	.body-text-s {
		font-size: 14.875px;
	}

}

@media only screen and (max-width: 999px) {

	.body-text-xl {
		font-size: 20px;
	}

	.body-text-l {
		font-size: 18.25px;
	}

	.body-text-m {
		font-size: 16.5px;
	}

	.body-text-s {
		font-size: 14.75px;
	}

}

@media only screen and (max-width: 767px) {

	.body-text-xl {
		font-size: 19.5px;
	}

	.body-text-l {
		font-size: 17.875px;
	}

	.body-text-m {
		font-size: 16.25px;
	}

	.body-text-s {
		font-size: 14.625px;
	}

}

@media only screen and (max-width: 549px) {

	.body-text-xl {
		font-size: 19px;
	}

	.body-text-l {
		font-size: 17.5px;
	}

	.body-text-m {
		font-size: 16px;
	}

	.body-text-s {
		font-size: 14.5px;
	}

}

@media only screen and (max-width: 399px) {

	.body-text-xl {
		font-size: 18.5px;
	}

	.body-text-l {
		font-size: 17.125px;
	}

	.body-text-m {
		font-size: 15.75px;
	}

	.body-text-s {
		font-size: 14.375px;
	}

}


/* 18.4. Copyright
   ========================================================================== */

.copyright-style {
	font-family: 'Montserrat', sans-serif;
	font-size: 10px;
	font-weight: 400;
	letter-spacing: 5px;
	line-height: 1.4;
	text-transform: uppercase;
}
/* 24.1. List item
   ========================================================================== */

.list__item {
	display: block !important;
	position: relative;
	line-height: 1;
}


/* 24.1.1. List item margins
   ========================================================================== */

/* Defaul margins 10px */
.list__item,
.list .list .list__item {
	margin-bottom: 10px;
}

.list__item:last-child,
.list .list .list__item:last-child {
	margin-bottom: 0;
}

.list_row .list__item {
	margin-top:   10px;
	margin-right: 10px;
	margin-bottom: 0;
}

.list_row.list_center .list__item {
	margin-left:  5px;
	margin-right: 5px;
}

.list_row.list_right .list__item {
	margin-left: 10px;
	margin-right: 0;
}

/* 1px margins */
.list_margin-1px .list__item,
.list .list.list_margin-1px .list__item {
	margin-bottom: 1px;
}

.list_margin-1px .list__item:last-child,
.list .list.list_margin-1px .list__item:last-child {
	margin-bottom: 0;
}

.list_margin-1px.list_row .list__item {
	margin-right: 1px;
	margin-bottom: 0;
}

.list_margin-1px.list_row.list_center .list__item {
	margin-left:  1px;
	margin-right: 1px;
}

.list_margin-1px.list_row.list_right .list__item {
	margin-left: 1px;
	margin-right: 0;
}

/* 20px margins */
.list_margin-20px .list__item,
.list .list.list_margin-20px .list__item {
	margin-bottom: 20px;
}

.list_margin-20px .list__item:last-child,
.list .list.list_margin-20px .list__item:last-child {
	margin-bottom: 0;
}

.list_margin-20px.list_row .list__item {
	margin-right: 20px;
	margin-bottom: 0;
}

.list_margin-20px.list_row.list_center .list__item {
	margin-left:  10px;
	margin-right: 10px;
}

.list_margin-20px.list_row.list_right .list__item {
	margin-left: 20px;
	margin-right: 0;
}

/* 30px margins */
.list_margin-30px .list__item,
.list .list.list_margin-30px .list__item {
	margin-bottom: 30px;
}

.list_margin-30px .list__item:last-child,
.list .list.list_margin-30px .list__item:last-child {
	margin-bottom: 0;
}

.list_margin-30px.list_row .list__item {
	margin-right: 30px;
	margin-bottom: 0;
}

.list_margin-30px.list_row.list_center .list__item {
	margin-left:  15px;
	margin-right: 15px;
}

.list_margin-30px.list_row.list_right .list__item {
	margin-left: 30px;
	margin-right: 0;
}


/* 24.1.2. List item icons
   ========================================================================== */

.list__item.dot,
.list__item.check,
.list__item.x {
	padding-left: 30px;
}

.list__item:before {
	position: absolute;
	left: 0;
	top: 50%;
	z-index: 4;
	font-family: Font Awesome;
	font-size: 10px;
	color: #fff;
	-webkit-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	        transform: translateY(-50%);
}

.list__item.black:before {
	color: #000;
}

.list__item.red:before {
	color: #6b131e;
}

.list__item.opacity-05:before {
	opacity: .5;
}

.list__item.dot:before {
	content: '\f111';
	font-size: 6px;
}

.list__item.check:before {
	content: '\f00c';
}

.list__item.x:before {
	content: '\f00d';
}


/* 24.2. List counter
   ========================================================================== */

.list_counter .list__item {
	padding-left: 30px;
}

.list_counter .list__item:before {
	font-family: 'Montserrat', sans-serif;
	font-weight: 400;
}

.list_counter .list__item:nth-child(1):before {
	content: '01';
}

.list_counter .list__item:nth-child(2):before {
	content: '02';
}

.list_counter .list__item:nth-child(3):before {
	content: '03';
}

.list_counter .list__item:nth-child(4):before {
	content: '04';
}

.list_counter .list__item:nth-child(5):before {
	content: '05';
}

.list_counter .list__item:nth-child(6):before {
	content: '06';
}

.list_counter .list__item:nth-child(7):before {
	content: '07';
}

.list_counter .list__item:nth-child(8):before {
	content: '08';
}

.list_counter .list__item:nth-child(9):before {
	content: '09';
}

.list_counter .list__item:nth-child(10):before {
	content: '10';
}

.list_counter .list__item:nth-child(11):before {
	content: '11';
}

.list_counter .list__item:nth-child(12):before {
	content: '12';
}

.list_counter .list__item:nth-child(13):before {
	content: '13';
}

.list_counter .list__item:nth-child(14):before {
	content: '14';
}

.list_counter .list__item:nth-child(15):before {
	content: '15';
}

.list_counter .list__item:nth-child(16):before {
	content: '16';
}

.list_counter .list__item:nth-child(17):before {
	content: '17';
}

.list_counter .list__item:nth-child(18):before {
	content: '18';
}

.list_counter .list__item:nth-child(19):before {
	content: '19';
}

.list_counter .list__item:nth-child(20):before {
	content: '20';
}
`
export default Layout